import React from "react";
import moment from "moment";

function Table(props) {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">标题</th>
          <th scope="col">分类</th>
          <th scope="col">日期</th>
          <th scope="col">访问量</th>
          <th scope="col">操作</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((news) => (
          <Row
            key={news._id}
            news={news}
            history={props.history}
            handleRemoveNews={props.handleRemoveNews}
          />
        ))}
      </tbody>
    </table>
  );
}

const newsCategory = {
  financial: "财经",
  sports: "体育",
  entertainment: "娱乐",
  speedyNews: "新闻速递"
};

function Row(props) {
  const { news: data, history, handleRemoveNews } = props;
  const date = moment(data.fetchDate, "YYYY-MM-DD").fromNow();
  return (
    <tr className="table-row-container">
      <th scope="row">{data.htmlContent.title}</th>
      <td>{newsCategory[data.category]}</td>
      <td>{date}</td>
      <td>{data.views}</td>
      <td className="bottom-gutter">
        <button
          onClick={() => history.push(`/news/${data._id}`)}
          className="btn btn-sm btn-light"
        >
          详情
        </button>
        <button
          onClick={() => handleRemoveNews(data._id)}
          className="btn btn-sm btn-danger"
        >
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
}

export default Table;
