import axios from "axios";
import API from "../constants/api";

export const fetchAllNews = (filters) => {
  return axios.get(`${API}/news/get/all`, {
    params: {
      ...filters
    }
  });
};

export const removeSingleNews = (id) => {
  return axios.post(`${API}/news/post/remove`, { id });
};

export const fetchSingleNews = (id) => {
  return axios.get(`${API}/news/get`, {
    params: { id }
  });
};

export const fetchNewsCategory = () => {
  return axios.get(`${API}/news/`)
}

// export const reviewListing = (id, passReview) => {
//   return axios.post(`${API}/listings/post/review`, { id, passReview });
// };

// export const stickListing = (id, duration) => {
//   return axios.post(`${API}/listings/post/sticky`, { id, duration });
// };
