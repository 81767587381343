import React, { Component } from "react";
import axios from "axios";
import API from "../../constants/api";

export default class TestPage extends Component {
  render() {
    return (
      <div>
        <input
          type="file"
          name="files"
          multiple
          onChange={(e) => {
            const formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
              const element = e.target.files[i];
              formData.append("files", element);
            }
            axios
              .post(`${API}/listings/post/image`, formData)
              .then((resp) => resp.data)
              .then((data) => {
                console.log(data);
              });
          }}
        />
        <img
          src="https://s3.amazonaws.com/pocket-life-listing-images/1551766343421-6894.jpg"
          alt=""
        />
      </div>
    );
  }
}
