import React from "react";
import moment from "moment";
import originalIcon from "../../assets/icons/original.svg";
import stickyIcon from "../../assets/icons/sticky.png";
function Table(props) {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">标题</th>
          <th scope="col">分类</th>
          <th scope="col">日期</th>
          <th scope="col">访问量</th>
          <th scope="col">操作</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map(listing => (
          <Row
            key={listing._id}
            listing={listing}
            history={props.history}
            handleRemoveListing={props.handleRemoveListing}
          />
        ))}
      </tbody>
    </table>
  );
}

function Row(props) {
  const { listing: data, history, handleRemoveListing } = props;
  const hasImage = data.htmlContent.images.length > 0;
  const isOriginal = data.isOriginal;
  const isSticky = data.isSticky - Date.now() > 0;
  const date = moment(data.fetchDate, "YYYY-MM-DD").fromNow();
  return (
    <tr className="table-row-container">
      <th scope="row">
        {isOriginal ? <img src={originalIcon} alt="原创" /> : ""}
        {isSticky ? <img src={stickyIcon} alt="置頂" /> : ""}
        {hasImage ? <i className="fa fa-image" /> : ""}
        {data.htmlContent.title}
      </th>
      <td>
        {data.category}/{data.subCategory}
      </td>
      <td>{date}</td>
      <td>{data.views}</td>
      <td className="bottom-gutter">
        <button
          onClick={() => history.push(`/listing/${data._id}`)}
          className="btn btn-sm btn-light"
        >
          详情
        </button>
        <button
          onClick={() => handleRemoveListing(data._id)}
          className="btn btn-sm btn-danger"
        >
          <i className="fa fa-trash" />
        </button>
      </td>
    </tr>
  );
}

export default Table;
