import React, { Component } from "react";
import Table from "./Table";
import Swal from "sweetalert2";
import customStyles from "../../constants/modalStyle";
import ReactPaginate from "react-paginate";
import { getAllAds, getSingleAd, fetchAllAds, postNewAd } from "../../api/ads";
import queryString from "query-string";
import Modal from "react-modal";
import "./index.scss";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

const defaultFilter = {
  page: 1
};
Modal.setAppElement("#root");

export default class Advertisements extends Component {
  state = {
    ads: [],
    filters: {
      page: 1
    },
    modalIsOpen: false
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  componentDidMount = () => {
    this.getAllAds();
  };
  handlePageClick = value => {
    const { filters } = this.state;
    const { selected } = value;
    filters.page = selected + 1;
    this.updateUrlParams(filters);
  };
  getAllAds = filters => {
    fetchAllAds(filters || defaultFilter)
      .then(resp => resp.data)
      .then(data => {
        this.setState({
          ads: data.data,
          totalAdsCount: data.totalAdsCount,
          totalPageCount: data.totalPageCount
        });
      });
  };
  handleFilterInputChange = e => {
    const { filters } = this.state;
    const { name, type, value, checked } = e.target;
    filters[name] = type === "checkbox" ? checked : value;
    filters.page = 1;
    this.updateUrlParams(filters);
  };
  updateUrlParams = filters => {
    const newLocation = `/ads?${queryString.stringify(filters)}`;
    this.props.history.push(newLocation);
    this.setState({ filters }, () => this.getAllAds(filters));
  };
  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmitAds = () => {
    const { title, coverImage, expiration, phone, body } = this.state;
    const adsData = {
      title,
      coverImage,
      expiration: expiration * 1000 * 60 * 60 * 24 + Date.now(),
      phone,
      body
    };
    postNewAd(adsData).then(resp => {
      console.log(resp);
      if (resp.data) {
        Toast.fire({
          type: "success",
          title: "成功创建广告"
        });
        this.setState({
          modalIsOpen: false,
          title: "",
          coverImage: "",
          expiration: "",
          phone: "",
          body: ""
        });
      }
    });
  };

  render() {
    const {
      ads,
      modalIsOpen,
      title,
      coverImage,
      expiration,
      phone,
      body
    } = this.state;
    const { location, history } = this.props;
    return (
      <div className="container page">
        <button onClick={this.openModal}>新建广告</button>
        <Table data={ads} location={location} history={history} />
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={this.state.totalPageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          pageClassName={"page-item"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="新建广告"
        >
          <h2>新建广告</h2>
          <button className="modal-close" onClick={this.closeModal}>
            close
          </button>
          <div className="create-form">
            <div className="input-container">
              <label htmlFor="title">标题</label>
              <input
                type="text"
                name="title"
                onChange={this.handleInputChange}
                value={title}
              />
            </div>
            <div className="input-container">
              <label htmlFor="coverImage">封面图</label>
              <input
                type="text"
                name="coverImage"
                onChange={this.handleInputChange}
                value={coverImage}
              />
            </div>
            <div className="input-container">
              <label htmlFor="expiration">广告时长/天</label>
              <input
                type="text"
                name="expiration"
                onChange={this.handleInputChange}
                value={expiration}
              />
            </div>
            <div className="input-container">
              <label htmlFor="phone">联系电话</label>
              <input
                type="text"
                name="phone"
                onChange={this.handleInputChange}
                value={phone}
              />
            </div>
            <div className="input-container">
              <label htmlFor="title">内容</label>
              <textarea
                onChange={this.handleInputChange}
                row="50"
                name="body"
                value={body}
              />
            </div>
            <button onClick={this.handleSubmitAds}>创建广告</button>
          </div>
        </Modal>
      </div>
    );
  }
}
