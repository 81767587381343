import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ListingsPage from "./pages/Listings";
import Navbar from "./components/Navbar";
import "bootstrap/dist/css/bootstrap.css";
import "./global.scss";
import routes from "./constants/routes";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <React.Fragment>
            <Navbar />
            <Switch>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.key}
                    exact
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

export default App;
