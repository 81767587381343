import React, { Component } from "react";
import {
  fetchSingleListing,
  reviewListing,
  stickListing
} from "../../api/listings";
import "./index.scss";
import moment from "moment";

export default class ListingPage extends Component {
  state = {
    id: "",
    listing: {
      htmlContent: {}
    }
  };
  componentDidMount() {
    this.parsePageId();
  }
  parsePageId = () => {
    const id = this.props.location.pathname.split("/")[2];
    this.setState({ id }, () => this.fetchPageData());
  };
  fetchPageData = () => {
    fetchSingleListing(this.state.id)
      .then(resp => resp.data)
      .then(data => {
        this.setState({ listing: data.data });
      });
  };
  handleMakeSticky = async duration => {
    const day = 1000 * 60 * 60 * 24;
    const week = day * 7;
    const month = day * 30;
    const { id } = this.state;
    switch (duration) {
      case "day":
        await stickListing(id, day);
        break;
      case "week":
        await stickListing(id, week);
        break;
      case "month":
        await stickListing(id, month);
        break;
      default:
        break;
    }
    this.fetchPageData();
  };
  renderImages = () => {
    const dataImages = this.state.listing.htmlContent.images;
    let images = [];
    if (dataImages && dataImages.length > 0) {
      images = this.state.listing.htmlContent.images.map(item => (
        <img src={item} alt="image" />
      ));
    }
    console.log(images);
    return images;
  };
  renderReviewButton = () => {
    const { isReviewed, _id } = this.state.listing;
    if (isReviewed) {
      return (
        <button
          onClick={() => {
            reviewListing(_id, false).then(() => this.fetchPageData());
          }}
          className="btn btn-warning"
        >
          取消审核
        </button>
      );
    } else {
      return (
        <button
          onClick={() => {
            reviewListing(_id, true).then(() => this.fetchPageData());
          }}
          className="btn btn-success"
        >
          审核通过
        </button>
      );
    }
  };
  renderStickyButton = () => {
    return (
      <div className="sticky-buttons">
        <button
          onClick={() => this.handleMakeSticky("day")}
          className="btn btn-md btn-info"
        >
          置頂一天
        </button>
        <button
          onClick={() => this.handleMakeSticky("week")}
          className="btn btn-md btn-info"
        >
          置頂一周
        </button>
        <button
          onClick={() => this.handleMakeSticky("month")}
          className="btn btn-md btn-info"
        >
          置頂一月
        </button>
      </div>
    );
  };
  renderBody = () => (
    <p
      dangerouslySetInnerHTML={{ __html: this.state.listing.htmlContent.body }}
    />
  );
  renderStickyExpirationTime = () => {
    const { isSticky } = this.state.listing;
    if (typeof isSticky !== "number") {
      return <p>帖子沒有被置頂</p>;
    } else {
      const now = Date.now();
      const isExpired = isSticky - now;
      if (isExpired < 0) {
        return <p>帖子置頂已過期</p>;
      } else {
        return <p>過期時間：{moment(isSticky).fromNow()}</p>;
      }
    }
  };
  render() {
    const { listing } = this.state;
    return (
      <div>
        <div className="jumbotron">
          <span />
          <h1 className="display-4">
            {listing.category}/{listing.subCategory}
          </h1>
          <p className="lead">{listing.htmlContent.title}</p>

          <hr className="my-4" />
          {/* {this.renderImages()} */}
          {console.log(listing.htmlContent.images)}
          {listing.htmlContent.images &&
            listing.htmlContent.images.map(item => (
              <img src={item} alt="images" />
            ))}
          {this.renderBody()}
          {this.renderStickyExpirationTime()}
          {this.renderReviewButton()}
          {this.renderStickyButton()}
        </div>
      </div>
    );
  }
}
