import React, { Component } from "react";
import Table from "./Table";
import ReactPaginate from "react-paginate";
import { getAllNews, fetchAllNews, removeSingleNews } from "../../api/news";
import queryString from "query-string";

const defaultFilter = {
  page: 1
};
export default class NewsPage extends Component {
  state = {
    news: [],
    filters: {
      page: 1
    }
  };
  componentDidMount = () => {
    this.getAllNews();
  };
  handlePageClick = (value) => {
    const { filters } = this.state;
    const { selected } = value;
    filters.page = selected + 1;
    this.updateUrlParams(filters);
  };
  getAllNews = (filters) => {
    fetchAllNews(filters || defaultFilter)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({
          news: data.data,
          totalNewsCount: data.totalNewsCount,
          totalPageCount: data.totalPageCount
        });
      });
  };
  handleFilterInputChange = (e) => {
    const { filters } = this.state;
    const { name, type, value, checked } = e.target;
    filters[name] = type === "checkbox" ? checked : value;
    filters.page = 1;
    this.updateUrlParams(filters);
  };
  updateUrlParams = (filters) => {
    const newLocation = `/news?${queryString.stringify(filters)}`;
    this.props.history.push(newLocation);
    this.setState({ filters }, () => this.getAllNews(filters));
  };
  handleRemoveNews = (id) => {
    const confirmation = window.confirm("确定删除该新闻吗？");
    if (confirmation)
      removeSingleNews(id).then((resp) => {
        if (resp.data.success) {
          const { news } = this.state;
          news.forEach((item, i) => {
            if (item._id === id) {
              news.splice(i, 1);
            }
          });
          this.setState({ news });
        }
      });
  };
  fetchNewsCategories = () => {
    
  }

  render() {
    const { news } = this.state;
    const { location, history } = this.props;
    return (
      <div className="container page">
        <Table
          data={news}
          location={location}
          history={history}
          handleRemoveNews={this.handleRemoveNews}
        />
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={this.state.totalPageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          pageClassName={"page-item"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </div>
    );
  }
}
