import React, { Component } from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import "./index.scss";
import { getToken } from "../../utils/jwt";
import Logo from "../../assets/logo.png";

export default class Navbar extends Component {
  renderRoutes = () => {
    const links = routes
      .filter((item) => item.isNav)
      .map((route) => (
        <li key={route.key} className="nav-item">
          <Link className="nav-link" to={route.path}>
            {route.title}
          </Link>
        </li>
      ));
    return links;
  };
  componentDidMount = () => {
    const token = getToken();
    const currentLocation = window.location.pathname;
    if (!token && currentLocation !== "/signin") {
      window.location.href = "/signin";
    }
  };
  render() {
    const currentLocation = window.location.pathname;
    return (
      currentLocation !== "/signin" && (
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-container">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="logo" height="40" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">{this.renderRoutes()}</ul>
          </div>
        </nav>
      )
    );
  }
}
