import React, { Component } from "react";
import { fetchSingleNews } from "../../api/news";
import moment from "moment";
import "./index.scss";

export default class NewsDetailPage extends Component {
  state = {
    news: {
      htmlContent: {
        images: []
      }
    }
  };
  componentDidMount() {
    this.parsePageId();
  }
  parsePageId = () => {
    const id = this.props.location.pathname.split("/")[2];
    this.setState({ id }, () => this.fetchPageData());
  };
  fetchPageData = () => {
    fetchSingleNews(this.state.id)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({ news: data.data });
      });
  };
  renderHTML = () => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: this.state.news.htmlContent.body }}
      />
    );
  };
  render() {
    const { news } = this.state;
    const date = moment(news.fetchDate, "YYYY-MM-DD").calendar();
    return (
      <div className="container news-detail-page">
        <div className="row">
          <div className="col-lg-12">
            <button
              className="btn btn-warning mt-3"
              onClick={() => this.props.history.goBack()}
            >
              <i className="fa fa-arrow-left" />
              &nbsp;返回
            </button>
            <h1 className="mt-4">{news.htmlContent.title}</h1>
            <hr />
            <p>发布于 {date}</p>
            <hr />
            {this.renderHTML()}
            <hr />
          </div>
        </div>
      </div>
    );
  }
}
