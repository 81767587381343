import axios from "axios";
import API from "../constants/api";

export const fetchAllAds = filters => {
  return axios.get(`${API}/ads/get/all`, {
    params: {
      ...filters
    }
  });
};

// export const removeSingleNews = (id) => {
//   return axios.post(`${API}/ads/post/remove`, { id });
// };

export const fetchSingleAd = id => {
  return axios.get(`${API}/ads/get`, {
    params: { id }
  });
};

export const postNewAd = data => {
  return axios.post(`${API}/ads/post/new`, data);
};

// export const reviewListing = (id, passReview) => {
//   return axios.post(`${API}/listings/post/review`, { id, passReview });
// };

// export const stickListing = (id, duration) => {
//   return axios.post(`${API}/listings/post/sticky`, { id, duration });
// };
