import React, { useState } from "react";

function Filter(props) {
  const [search, setSearch] = useState('')
  const handleSetSearch = (e) => {
    setSearch(e.target.value)
  }
  return (
    <form className="filterWrapper">
      <div className="filter form-inline">
        <div className="form-group">
          <input
            className="form-check-input"
            onChange={(e) => props.handleInputChange(e)}
            type="checkbox"
            name="isOriginal"
            checked={props.isOriginal}
            id="isOriginal"
          />
          <label className="form-check-label" htmlFor="isOriginal">
            只显示原创
          </label>
        </div>
        <div className="form-group">
          <input
            className="form-check-input"
            onChange={(e) => props.handleInputChange(e)}
            type="checkbox"
            name="isNotReviewed"
            checked={props.isNotReviewed}
            id="isNotReviewed"
          />
          <label className="form-check-label" htmlFor="isNotReviewed">
            只显示未审核
          </label>
        </div>
        <div className="form-group">
          <input
            className="form-check-input"
            onChange={(e) => handleSetSearch(e)}
            type="text"
            name="search"
            value={search}
            placeholder="搜索关键词"
            id="search"
          />
          <button onClick={(e) => {
            e.preventDefault()
            props.handleInputChange({ target: { value: search, name: 'search' } })
          }}>搜索</button>
        </div>
      </div>
    </form>
  );
}

export default Filter;
