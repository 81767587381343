import React, { Component } from "react";
import "./index.scss";
import QR from "../../assets/qr.jpg";
import { login } from "../../api/users";
import { saveToken, getToken } from "../../utils/jwt";

export default class SigninPage extends Component {
  state = {
    username: "",
    password: ""
  };
  componentDidMount = () => {
    const token = getToken();
    if (token) {
      this.props.history.push("/");
    }
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = () => {
    login(this.state.username, this.state.password)
      .then((resp) => resp.data)
      .then((data) => {
        if (data.token) {
          saveToken(data.token);
          this.props.history.push("/");
        } else {
          alert("Login failed!");
        }
      });
  };
  render() {
    return (
      <div className="body text-center">
        <div className="form-signin">
          <img className="mb-4" src={QR} alt="" width="200" />
          <h1 className="h3 mb-3 font-weight-normal">消息通管理平台登錄</h1>
          <label htmlFor="inputEmail" className="sr-only">
            登錄賬號
          </label>
          <input
            onChange={this.handleInputChange}
            type="text"
            className="form-control"
            placeholder="賬號"
            name="username"
            autoFocus
          />
          <label htmlFor="inputPassword" className="sr-only">
            Password
          </label>
          <input
            onChange={this.handleInputChange}
            type="password"
            className="form-control"
            placeholder="密碼"
            name="password"
            autoComplete="on"
          />
          <button
            onClick={this.handleSubmit}
            className="btn btn-lg btn-primary btn-block"
          >
            Sign in
          </button>
        </div>
      </div>
    );
  }
}
