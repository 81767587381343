import React, { Component } from "react";
import queryString from "querystring";
import Table from "./Table";
import { fetchAllListings, removeSingleListing } from "../../api/listings";
import "./index.scss";
import getUrlParams from "../../utils/getUrlParams";
import Filter from "./Filter";
import ReactPaginate from "react-paginate";

const defaultFilter = {
  isOriginal: false,
  isNotReviewed: false,
  page: 1
};
export default class ListingsPage extends Component {
  state = {
    listings: [],
    filters: {
      isOriginal: false,
      isNotReviewed: false,
      page: 1
    }
  };

  componentDidMount = () => {
    let filters = queryString.parse(window.location.search.slice(1))
    filters = (Object.entries(filters).length > 0 && filters.constructor != Object) || defaultFilter
    this.setState({ filters }, () => this.getAllListings({ ...filters }));
  };
  handlePageClick = (value) => {
    const { filters } = this.state;
    const { selected } = value;
    filters.page = selected + 1;
    this.updateUrlParams(filters);
  };
  getAllListings = (filters) => {
    fetchAllListings(filters || defaultFilter)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({
          listings: data.data,
          totalListingCount: data.totalListingCount,
          totalPageCount: data.totalPageCount
        });
      });
  };
  handleFilterInputChange = (e) => {
    const { filters } = this.state;
    const { name, type, value, checked } = e.target;
    filters[name] = type === "checkbox" ? checked : value;
    filters.page = 1;
    this.updateUrlParams(filters);
  };
  updateUrlParams = (filters) => {
    const newLocation = `/listings?${queryString.stringify(filters)}`;
    this.props.history.push(newLocation);
    this.setState({ filters }, () => this.getAllListings(filters));
  };
  handleRemoveListing = (id) => {
    const confirmation = window.confirm("确定删除该帖子吗？");
    if (confirmation)
      removeSingleListing(id).then((resp) => {
        if (resp.data.success) {
          const { listings } = this.state;
          listings.forEach((item, i) => {
            if (item._id === id) {
              listings.splice(i, 1);
            }
          });
          this.setState({ listings });
        }
      });
  };
  render() {
    const { listings } = this.state;
    const { isOriginal, isNotReviewed } = this.state.filters;
    return (
      <div className="container page">
        <Filter
          isOriginal={isOriginal}
          isNotReviewed={isNotReviewed}
          handleInputChange={this.handleFilterInputChange}
        />
        <Table
          data={listings}
          location={this.props.location}
          history={this.props.history}
          handleRemoveListing={this.handleRemoveListing}
        />
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageCount={this.state.totalPageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          pageClassName={"page-item"}
          subContainerClassName={"page-item"}
          activeClassName={"active"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextLinkClassName={"page-link"}
        />
      </div>
    );
  }
}
