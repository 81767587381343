import React from "react";
import moment from "moment";

function Table(props) {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col">标题</th>
          <th scope="col">过期日期</th>
          <th scope="col">分享量</th>
          <th scope="col">访问量</th>
          <th scope="col">操作</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((ad) => (
          <Row key={ad._id} ad={ad} history={props.history} />
        ))}
      </tbody>
    </table>
  );
}

function Row(props) {
  const { ad: data, history } = props;
  const expiration = moment(new Date(data.expiration), "YYYY-MM-DD").fromNow();
  return (
    <tr className="table-row-container">
      <th scope="row">{data.title}</th>
      <td>{expiration}</td>
      <td>{data.shares}</td>
      <td>{data.views}</td>
      <td className="bottom-gutter">
        <button
          onClick={() => history.push(`/ads/${data._id}`)}
          className="btn btn-sm btn-light"
        >
          详情
        </button>
      </td>
    </tr>
  );
}

export default Table;
