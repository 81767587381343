import React, { Component } from "react";
import { fetchSingleAd } from "../../api/ads";
import moment from "moment";

export default class AdvertisementPage extends Component {
  state = {
    ad: {}
  };
  componentDidMount() {
    this.parsePageId();
  }
  parsePageId = () => {
    const id = this.props.location.pathname.split("/")[2];
    this.setState({ id }, () => this.fetchPageData());
  };
  fetchPageData = () => {
    fetchSingleAd(this.state.id)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({ ad: data.data });
      });
  };
  renderHTML = () => {
    return <div dangerouslySetInnerHTML={{ __html: this.state.ad.body }} />;
  };
  render() {
    const { ad } = this.state;
    const date = moment(new Date(ad.expiration), "YYYY-MM-DD").calendar();
    return (
      <div className="container news-detail-page">
        <div className="row">
          <div className="col-lg-12">
            <button
              className="btn btn-warning mt-3"
              onClick={() => this.props.history.goBack()}
            >
              <i className="fa fa-arrow-left" />
              &nbsp;返回
            </button>
            <h1 className="mt-4">{ad.title}</h1>
            <hr />
            <p>发布于 {date}</p>
            <hr />
            {this.renderHTML()}
            <hr />
          </div>
        </div>
      </div>
    );
  }
}
