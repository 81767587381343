import axios from "axios";
import API from "../constants/api";

export const fetchAllListings = (filters) => {
  return axios.get(`${API}/listings/get/all`, {
    params: {
      ...filters
    }
  });
};

export const fetchSingleListing = (id) => {
  return axios.get(`${API}/listings/get`, {
    params: { id, format: 'html' }
  });
};

export const removeSingleListing = (id) => {
  return axios.post(`${API}/listings/post/remove`, { id });
};

export const reviewListing = (id, passReview) => {
  return axios.post(`${API}/listings/post/review`, { id, passReview });
};

export const stickListing = (id, duration) => {
  return axios.post(`${API}/listings/post/sticky`, { id, duration });
};
