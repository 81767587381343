import ListingsPage from "../pages/Listings";
import ListingPage from "../pages/Listing";
import TestPage from "../pages/Test";
import SigninPage from "../pages/Signin";
import NewsPage from "../pages/News";
import NewsDetailPage from "../pages/NewsPage";
import Advertisements from "../pages/Advertisements";
import AdvertisementPage from "../pages/Advertisement";

const routes = [
  {
    title: "首页",
    key: "home",
    path: "/",
    component: null,
    isNav: true
  },
  {
    title: "Listing Detail",
    key: "listing",
    path: "/listing/:id",
    component: ListingPage,
    isNav: false
  },
  {
    title: "发帖管理",
    key: "listings",
    path: "/listings",
    component: ListingsPage,
    isNav: true
  },
  {
    title: "登录",
    key: "signin",
    path: "/signin",
    component: SigninPage,
    isNav: false
  },
  {
    title: "test",
    key: "test",
    path: "/test",
    component: TestPage,
    isNav: false
  },

  {
    title: "新闻详情",
    key: "newsPage",
    path: "/news/:id",
    component: NewsDetailPage,
    isNav: false
  },
  {
    title: "新闻管理",
    key: "news",
    path: "/news",
    component: NewsPage,
    isNav: true
  },
  {
    title: "广告管理",
    key: "ads",
    path: "/ads",
    component: Advertisements,
    isNav: true
  },
  {
    title: "广告详情",
    key: "ad",
    path: "/ads/:id",
    component: AdvertisementPage
  }
];

export default routes;
